import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  WrenchScrewdriverIcon,
  CheckCircleIcon,
  PlayIcon,
  BoltIcon,
  PencilIcon,
  CalendarDaysIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/solid';
import Issue from './Issue.jsx';
import axiosInstance from '../../axiosInstance.js';
import { fetchIssuesByRuleId } from '../../slices/issuesSlice';
import * as Sentry from '@sentry/react';
import {
  setIsOutOfCreditsOpen,
  setIsCreateScheduleOpen,
  setIsCreateFixOpen,
  setPreventRunSeconds,
  setIsOldDataOpen,
  setIsPreventRunOpen,
  setPreventRunMessage
} from '../../slices/uiSlice';
import { addNotification } from '../../slices/notificationSlice.js';
import { addRunningRule, removeRunningRule } from '../../slices/runningSlice';
import downloadIssues from './downloadIssues.js';

const IssueColumn = ({ selectedRuleId, selectedObject, filteredIssues }) => {
  const dispatch = useDispatch();
  const rules = useSelector((state) => state.rules.data);
  const selectedRule = rules.find((rule) => rule._id === selectedRuleId);
  const credits = useSelector((state) => state.orgCredits.credits);
  const isRunning = useSelector((state) =>
    state.running.runningRuleIds.includes(selectedRuleId)
  );

  // This constant controls how many issues we actually render
  const MAX_ISSUES_TO_SHOW = 200;

  // Slice the first 200 to display in DOM
  const issuesToRender =
    filteredIssues.length > MAX_ISSUES_TO_SHOW
      ? filteredIssues.slice(0, MAX_ISSUES_TO_SHOW)
      : filteredIssues;

  const handleCreateFixClick = () => {
    if (credits < 0) {
      dispatch(setIsOutOfCreditsOpen(true));
    } else {
      dispatch(setIsCreateFixOpen(true));
    }
  };

  const getRelativeDate = (lastRunDate) => {
    const now = new Date();
    const diffTime = Math.abs(now - lastRunDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 0) {
      return 'Today';
    } else {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    }
  };

  const handleRunFix = async () => {
    if (!selectedRule) {
      console.error('No rule selected.');
      return;
    }

    const lastRun = new Date(selectedRule.lastRun);
    const now = new Date();
    const minutesSinceLastRun = (now - lastRun) / (1000 * 60);

    if (minutesSinceLastRun > 10) {
      dispatch(setIsOldDataOpen(true));
      return;
    }

    if (credits < 0) {
      dispatch(setIsOutOfCreditsOpen(true));
      return;
    }

    dispatch(addRunningRule(selectedRuleId));

    try {
      const response = await axiosInstance.post('/api/run-fix', {
        rule: selectedRule,
      });

      if (response.data.prevent) {
        dispatch(setIsPreventRunOpen(true));
        dispatch(setPreventRunMessage(response.data.message));
        dispatch(setPreventRunSeconds(response.data.seconds));
        return;
      }

      dispatch(fetchIssuesByRuleId(response.data.rule._id));

      dispatch(
        addNotification({
          type: 'success',
          message: 'Fix run successfully',
          description: `Fix for rule: ${selectedRule.ruleName} run successfully.`,
        })
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Authentication Session Expired',
            description:
              'Authentication session expired, please log in again to refresh token.',
          })
        );
      } else {
        Sentry.captureException(error);
        console.error('Error running fix:', error);
        dispatch(
          addNotification({
            type: 'error',
            message: 'Error applying fix',
            description: `Failed to apply the fix for rule: ${selectedRule.ruleName}`,
          })
        );
      }
    } finally {
      dispatch(removeRunningRule(selectedRuleId));
    }
  };

  const handleAcceptAllFixes = async () => {
    if (!selectedRule) {
      console.error('No rule selected.');
      return;
    }

    if (credits < 0) {
      dispatch(setIsOutOfCreditsOpen(true));
      return;
    }

    dispatch(addRunningRule(selectedRuleId));

    try {
      await axiosInstance.post('/api/accept-all-fixes', {
        ruleId: selectedRule._id,
      });
      dispatch(fetchIssuesByRuleId(selectedRule._id));
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error accepting all fixes:', error);
    } finally {
      dispatch(removeRunningRule(selectedRuleId));
    }
  };

  const handleCreateScheduleClick = () => {
    dispatch(setIsCreateScheduleOpen(true));
  };

  const handleDownloadIssues = () => {
    if (selectedRule) {
      // Still downloads ALL filtered issues, not just the 200 displayed
      downloadIssues(filteredIssues, selectedRule.ruleName);
    } else {
      console.error('No rule selected.');
    }
  };

  // Determine which buttons to show
  const showCreateFix = selectedRule && !selectedRule.fix;
  const showRunFix = selectedRule && selectedRule.fix && filteredIssues.length > 0;
  const showEditFix = selectedRule && selectedRule.fix && !isRunning;
  const showSchedule = showEditFix;
  const showRunRuleMessage = selectedRule && selectedRule.lastRun == null;
  const showNoIssuesMessage =
    selectedRule && filteredIssues.length === 0 && selectedRule.lastRun != null;
  const showAcceptAll = selectedRule && filteredIssues.some((issue) => issue.status === 'Pending');

  return (
    <div className="w-1/2 xl:w-1/2 overflow-auto h-[calc(100vh-96px)] mt-2 border border-gray-200 bg-[#f9f9f9] rounded-lg px-2 mr-4 pb-10">
      {/* Header */}
      <div className="sticky top-0 z-10 py-2 bg-gradient-to-t from-transparent via-[#f9f9f9]/100 to-[#f9f9f9] pb-4">
        <div className="flex justify-between items-center p-2">
          {/* Left side: Issues header */}
          <div className="flex">
            <h2 className="text-xl font-semibold">Issues</h2>

            {/* Show either "(N)" or "Showing 200 of N" */}
            {filteredIssues.length > MAX_ISSUES_TO_SHOW ? (
              <span
                className="ml-1 text-sm text-gray-500 leading-none"
                style={{ marginTop: '8px' }}
              >
                (showing <strong>{issuesToRender.length}</strong> of <strong>{filteredIssues.length}</strong>)
              </span>
            ) : (
              <span
                className="ml-1 text-sm text-gray-500 leading-none"
                style={{ marginTop: '8px' }}
              >
                ({filteredIssues.length})
              </span>
            )}
          </div>

          {/* Right side: Buttons */}
          <div
            className="flex items-center space-x-2"
            style={{ minHeight: '40px' }}
          >
            {showSchedule && (
              <button
                onClick={handleCreateScheduleClick}
                className="flex items-center rounded bg-[#F4BBFF] px-2 py-1 text-xsm text-black shadow-sm hover:bg-[#e0a9f2] focus:outline-none"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                }}
              >
                Schedule
                <CalendarDaysIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            )}

            {showEditFix && (
              <button
                onClick={handleCreateFixClick}
                className="flex items-center rounded bg-gray-300 px-2 py-1 text-xsm text-gray-900 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                }}
              >
                Edit Fix
                <PencilIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            )}

            {showRunFix && (
              <button
                className={`flex items-center rounded px-2 py-1 text-xsm text-white shadow-sm ${
                  isRunning
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-indigo-600 hover:bg-indigo-500'
                }`}
                onClick={!isRunning ? handleRunFix : undefined}
                disabled={isRunning}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                }}
              >
                Run Fix
                <WrenchScrewdriverIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            )}

            {showCreateFix && (
              <button
                onClick={handleCreateFixClick}
                className="flex items-center rounded bg-[#F4BBFF] px-2 py-1 text-xsm text-black shadow-sm hover:bg-[#e0a9f2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4BBFF]"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                }}
              >
                Create a Fix
                <BoltIcon className="ml-2 h-4 w-4" aria-hidden="true" />
              </button>
            )}

            {showAcceptAll && (
              <button
                className="flex items-center rounded bg-green-400 px-2 py-1 text-xsm text-gray-800 shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4BBFF]"
                onClick={handleAcceptAllFixes}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '30px',
                }}
              >
                Accept All
                <CheckCircleIcon className="ml-1 h-5 w-5" aria-hidden="true" />
              </button>
            )}

            {(showRunFix || showCreateFix) && (
              <button
                className="rounded p-1 text-indigo-500 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={handleDownloadIssues}
                title="Download Issues"
              >
                <ArrowDownTrayIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Body */}
      <div>
        {showRunRuleMessage && (
          <div className="text-center pt-20">
            <p className="text-gray-500">
              Hit the{' '}
              <PlayIcon className="inline h-5 w-5 text-indigo-600" aria-hidden="true" /> to
              find Issues
            </p>
          </div>
        )}

        {showNoIssuesMessage && (
          <div className="text-center pt-20">
            <h2 className="text-xl font-semibold text-gray-600">No Issues!</h2>
            <p className="text-gray-400 mt-1">
              Aren't you just <em>perfect</em>.
            </p>
            <p className="text-xxs leading-5 text-gray-500 mt-4">
              Last run:{' '}
              {selectedRule.lastRun ? (
                <time dateTime={selectedRule.lastRun}>
                  <strong>
                    {new Date(selectedRule.lastRun)
                      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                      .split(' ')[0]}
                  </strong>
                  {new Date(selectedRule.lastRun)
                    .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                    .split(' ')[1]}
                  , {getRelativeDate(new Date(selectedRule.lastRun))}
                </time>
              ) : (
                'Never'
              )}
            </p>
          </div>
        )}

        {!showRunRuleMessage && !showNoIssuesMessage && (
          <ul className="space-y-2">
            {/* Render only the first 200 issues in the DOM */}
            {issuesToRender.map((issue) => (
              <Issue key={issue._id} issue={issue} issueLayout={selectedRule.issueLayout} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default IssueColumn;