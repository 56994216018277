import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssueById } from '../../slices/issuesSlice';
import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/solid';
import IssueFixInfo from './IssueFixInfo';
import IssueFixPreview from './IssueFixPreview';

const Issue = ({ issue }) => {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const runningRuleIds = useSelector((state) => state.running.runningRuleIds);
  const isRunning = runningRuleIds.includes(issue.ruleId);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'Pending':
        return 'bg-[#F4BBFF] ring-1 ring-[#EB6ECB]';
      case 'Error':
        return 'bg-red-100 ring-1 ring-[#EB6ECB]';
      case 'Resolved':
        return 'bg-green-100 text-green-800 ring-1 ring-green-300';
      default:
        return 'bg-[#C2B9F6] ring-1 ring-[#8A79EC]';
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(issue.linkToRecord);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 5000);
  };

  const handleAcceptFix = async () => {
    try {
      await axios.post('http://localhost:5001/api/accept-fix', { issueId: issue._id });
      dispatch(fetchIssueById(issue._id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveFix = async () => {
    try {
      await axios.post('http://localhost:5001/api/remove-fix', { issueId: issue._id });
      dispatch(fetchIssueById(issue._id));
    } catch (error) {
      console.error(error);
    }
  };

  const formatValue = (field) => {
    // Handle numeric formats
    if (['currency', 'Currency', 'number', 'Number'].includes(field.type)) {
      return Number(field.value).toLocaleString();
    }
  
    // Handle date-only fields (e.g., "2023-10-12")
    if (['date', 'Date'].includes(field.type)) {
      // You can use any date formatting you like here
      const dateObj = new Date(field.value);
      // Example: "Oct 12, 2023"
      return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }
  
    // Handle date-time fields (e.g., "2023-10-12T14:05:00Z")
    if (['datetime', 'DateTime'].includes(field.type)) {
      // Again, format to your preference
      const dateTimeObj = new Date(field.value);
      // Example: "Oct 12, 2023, 2:05 PM"
      return dateTimeObj.toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      });
    }
  
    // Default: just return the raw value
    return field.value;
  };

  const issueTitleField = issue.previewFields.find((field) => field.location === 'issueTitle');
  const otherFields = issue.previewFields.filter((field) => field.location !== 'issueTitle');
  const colors = ['text-red-700', 'text-blue-700', 'text-green-700', 'text-yellow-700'];
  const evaluatedCondition = issue.fix?.conditions.find((condition) => condition.evaluation === true);

  return (
    <li className="text-xs p-3 border rounded-lg bg-white flex flex-wrap relative">
      <div className="w-full flex justify-between items-center mb-4">
        {issueTitleField && (
          <h2 className="text-xsm font-medium flex items-center">
            <strong>{issueTitleField.label}</strong>:
            <a
              href={issue.linkToRecord}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:underline no-underline ml-1"
            >
              {issueTitleField.value}
            </a>
            <div className="flex items-center ml-2">
              <img
                src="/assets/Salesforce_Corporate_Logo_RGB.png"
                alt="Salesforce Logo"
                className="h-4 w-auto"
              />
              {linkCopied ? (
                <span className="text-green-500 text-xxs ml-1">link copied!</span>
              ) : (
                <DocumentDuplicateIcon
                  className="h-3.5 w-3.5 text-gray-400 hover:text-indigo-500 cursor-pointer ml-1"
                  onClick={handleCopyLink}
                />
              )}
            </div>
          </h2>
        )}
        <div className="flex items-center space-x-1">
          <p
            className={classNames(
              'text-gray',
              getStatusStyles(issue.status),
              'whitespace-nowrap rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset'
            )}
          >
            {issue.status}
          </p>
          <InformationCircleIcon
            className="h-4 w-4 text-gray-400 hover:text-[#8A79EC] cursor-pointer"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
          />
          {showInfo && (
            <div className="absolute z-10 top-8 right-0 bg-white border rounded-lg shadow-lg p-2 w-80">
              <IssueFixInfo issue={issue} />
            </div>
          )}
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-1">
        {otherFields.map((field, index) => (
          <div key={index} className="flex gap-1">
            <p className="font-medium">
              <strong>{field.label}:</strong>
            </p>
            <p
              className={classNames(
                field.inQuery
                  ? `text-xxs ${colors[index % colors.length]} bg-gray-100 p-1 rounded border font-mono`
                  : ''
              )}
            >
              {formatValue(field)}
            </p>
          </div>
        ))}
      </div>

      {issue.fix && evaluatedCondition && <IssueFixPreview evaluatedCondition={evaluatedCondition} />}

      <div className="w-full mt-4 text-xxs">
        <p>
          <strong>Last Run:</strong>{' '}
          {`${new Date(issue.createdAt)
            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            .replace(' ', '')
            .replace(/(AM|PM)/, '$1,')} ${new Date(issue.createdAt).toLocaleDateString([], {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
          })}`}
        </p>
      </div>

      {isRunning && (
        <div className="absolute bottom-2 right-2">
          <l-mirage size="60" speed="2.5" color="#6366f1"></l-mirage>
        </div>
      )}
    </li>
  );
};

export default Issue;